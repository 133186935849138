import React, { Children, cloneElement, isValidElement, ReactElement, forwardRef, useImperativeHandle } from 'react';

// Hooks
import { useForm, OnSubmit } from 'react-hook-form';

// Components
import { Field, Button } from 'Components/Atoms';

// Styles
import './styles.less';

// Typings
type TChildren = ReactElement | ReactElement[];

type TProps = {
  autoComplete?: string;
  children: TChildren;
  onSubmit?: OnSubmit<Record<string | number | symbol, any>>;
  onReset?: Function;
  validationSchema?: object;
};

const AppForm = forwardRef(
  ({ children, onSubmit = () => {}, onReset, autoComplete = 'off', validationSchema }: TProps, ref) => {
    const { getValues, handleSubmit, formState, control, errors, reset, triggerValidation } = useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      validationSchema,
    });

    const { dirty, isValid } = formState;

    // Methods in here are available to be used in the parent component
    useImperativeHandle(ref, () => ({
      getFormValues() {
        return getValues();
      },

      resetForm() {
        return reset();
      },

      revalidate() {
        return triggerValidation()
      }
    }));

    const recursivelyMapChildren = (children: TChildren) => {
      return Children.map(children, child => {
        const { type, key } = child;

        // If the child is not a valid React element,
        // such as it being only text
        // return it wihout manipulation
        //
        // https://reactjs.org/docs/react-api.html#isvalidelement
        if (!isValidElement(child)) {
          return child;
        }

        // Append reset method from `useForm` hook to the `reset` button
        //
        // If a child of type Button
        // has a key === 'reset'
        // the element is cloned and the `reset` method from the hook
        // is appended to it.
        //
        // When the button is clicked, the form will reset to the provided or initial state
        if (type === Button && typeof key !== 'undefined' && key === 'reset') {
          return cloneElement(child, {
            // @ts-ignore
            ...child.props,
            onClick: onReset,
            disabled: !dirty || !isValid,
          });
        }

        // Append submit method to the `submit` button
        //
        // If a child of type Button
        // has a key === 'submit'
        // the element is cloned and the `onSubmit` method is appended to it
        if (type === Button && typeof key !== 'undefined' && key === 'submit') {
          return cloneElement(child, {
            // @ts-ignore
            ...child.props,
            onClick: onSubmit,
            disabled: !dirty || !isValid,
          });
        }

        // Enable/disable open modal button
        //
        // If a child of type Button
        // has a key === 'openModal'
        // the element is cloned and the disabled prop is set
        if (type === Button && typeof key !== 'undefined' && key === 'openModal') {
          return cloneElement(child, {
            // @ts-ignore
            ...child.props,
            disabled: !dirty || !isValid,
          });
        }

        // Append the `control` object as provided from the `useForm` hook
        //
        // For a more detailed look what this actually is:
        // https://react-hook-form.com/api/#control
        if (type === Field) {
          return cloneElement(child, {
            // @ts-ignore
            ...child.props,
            control,
            key: child.props.name,
            error: errors[child.props.name],
          });
        }

        // Recursion of child elements
        return cloneElement(child, {
          // @ts-ignore
          ...child.props,
          children: recursivelyMapChildren(child.props.children),
        });
      });
    };

    return (
      <form className="tbf-form" onSubmit={handleSubmit(onSubmit)} autoComplete={autoComplete}>
        {recursivelyMapChildren(children)}
      </form>
    );
  }
);

export default AppForm;
